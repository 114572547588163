module.exports = {
  baseurl: process.env.VUE_APP_API_URL,

  // Parser for common error messages

  defaultErrorHandler(err) {
    if (err.response) {
      err.response.json().then((json) => {
        var message = json.message;
        if (message.length) {
          message = message.map(function(msg) {
            return msg.messages.map(function(m) {
              return m.message;
            }).join(", ");
          }).join(", ");
        } else if (message.response) {
          message = message.response;
        } else if (typeof message != "string") {
          message = "Ett fel uppstod";
        }
        alert(message);
      });
    } else {
      alert(err);
    }
    console.log(err);
  },

  // Wrappers for fetch-API

  get(config) {
    config.method = "get";
    return this.fetch(config.url, config);
  },

  post(config) {
    config.method = "post";
    return this.fetch(config.url, config);
  },

  fetch(url, config) {
    url = this.baseurl + url;
    if (this.fetchItem('m-ut') && !config.skipAuth) {
      config = Object.assign({
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.fetchItem('m-ut')
        }
      }, config);
    } else {
      config = Object.assign({
        headers: {
          'Content-Type': 'application/json'
        }
      }, config);
    }
    if (config.body) {
      config.body = JSON.stringify(config.body);
    }
    return fetch(url, config).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        return res.json();
      } else {
        var error = new Error(res.statusText);
        error.response = res;
        throw error;
      }
    });
  },

  /**
   * Saves key string with ttl to localStorage
   * @param {string} key
   * @param {*} data
   * @param {number} ttl in minutes
   */
  storeItem(key, data, ttl) {
    const item = {
      value: data,
      expiry: Date.now() + ttl * 60 * 1000, // Convert mins to ms
    }
    if (ttl === 0) {
      delete item.expiry;
    }
    localStorage.setItem(key, JSON.stringify(item))
  },

  fetchItem(key) {
    const jsonItem = localStorage.getItem(key);
    if (!jsonItem) {
      return null
    }
    const item = JSON.parse(jsonItem);

    if (Date.now() > item.expiry) {
      localStorage.removeItem(key)
      return null
    }
    return item.value
  },

  mixpanel(method, arg0, arg1, arg2) {
    if (!window.mixpanel) {
      return false;
    }
    var mix = window.mixpanel;
    if (method.indexOf(".") !== -1) {
      var arr = method.split(".");
      mix = mix[arr[0]];
      method = arr[1];
    }
    if (method == "track" && !arg1) {
      arg1 = {};
    }
    if (arg2) {
      mix[method](arg0, arg1, arg2);
    } else if (arg1) {
      mix[method](arg0, arg1);
    } else if (arg0) {
      mix[method](arg0);
    }
  },

  /**
   * Send event to GTM / Google Analytics
   * @param {string} event
   * @param {object} data
   */
  sendEvent(event, data) {
    if (!event || (!window.dataLayer && !window.gtag && !window.ga)) {
      return false;
    }

    let analytics = (typeof data == 'object' ? data : {});

    if (window.dataLayer) {
      const action = Object.keys(analytics)[0];
      const label = Object.values(analytics)[0];
      var log = {
        'event': 'mindfully.event',
        'mindfully.event.name': event,
        'mindfully.event.action': action ? action : '',
        'mindfully.event.label': label ? label : ''
      };
      window.dataLayer.push(log);
    } else if (window.gtag) {
      const label = Object.keys(analytics)[0];
      const value = Object.values(analytics)[0];
      window.gtag('event', event, {
       'event_category': event,
       'event_label': label ? label : '',
       'value': value ? value : ''
      });
    } else if (window.ga) {
      const label = Object.keys(analytics)[0];
      const value = Object.values(analytics)[0];
      window.ga('send', 'event', event, (label ? label : 'click'), (value ? value : ''));
    }
    return true;
  }
}