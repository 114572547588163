import { createApp } from 'vue'
import Mindfully from './common';

const hash = (window.originalLocationHash && window.originalLocationHash.length > 0) ? window.originalLocationHash : window.location.hash;
// Stripe subscription successful callback, clear userData to force refresh
if (hash.length !== 0 && hash.indexOf('success') !== -1) {
  localStorage.removeItem('mindfullyUserData');
  const subscriptionName = hash.split('#success:')[1];
  Mindfully.sendEvent('mindfully_successful_purchase', { subscription: subscriptionName });
  window.location = process.env.VUE_APP_STRIPE_SUCCESS_CALLBACK;
} else {

  window['mindfully'] = {
    baseurl: process.env.VUE_APP_API_URL
  };

  if (document.querySelector("#mindfully-account")) {
    import(/* webpackChunkName: "am" */ "./AccountManager.vue").then(AccountManager => {
      var app = window.mindfullyApp = createApp(AccountManager.default).mount('#mindfully-account')
      var links = document.querySelectorAll('[id^=mindfullytrigger]');
      for (var i=0; i<links.length; i++) {
        links[i].addEventListener('click', function(e) {
          e.preventDefault();
          app.showAccountModal();
        })
      }
      app.manageButton = true;
    });
  } else {
    import(/* webpackChunkName: "am" */ "./AccountManager.vue").then(AccountManager => {
      var div = document.createElement('div');
      document.body.appendChild(div);
      window['mindfullyApp'] = createApp(AccountManager.default).mount(div)
    });
  }

  if (document.querySelector("#password-reset")) {
    import(/* webpackChunkName: "pr" */ "./PasswordReset.vue").then(PasswordReset => {
      createApp(PasswordReset.default).mount('#password-reset')
    })
  }
}